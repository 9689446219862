import { useSession } from 'next-auth/react'
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import StudentDetails from '~/types/entities/student-details.ts'

type StudentType = Pick<StudentDetails, 'id' | 'avatar' | 'name'>

const Context = createContext<{
  localUser?: StudentType
  setLocalUser: (userIndex?: number) => void
}>(
  undefined as unknown as {
    localUser?: StudentType
    setLocalUser: (userIndex?: number) => void
  }
)

export const LocalUserContext = ({ children }: { children: ReactNode }) => {
  const { data } = useSession()
  const [localUser, setLocalUser] = useState<StudentType | undefined>()
  const isClient = typeof window !== 'undefined'

  const localUserIndex = isClient ? localStorage.getItem('local-user') : null

  useEffect(() => {
    if (localUserIndex && data?.user?.guardianDetails) {
      setLocalUser(data?.user.guardianDetails?.students[parseInt(localUserIndex, 10)])
    }
  }, [data?.user?.guardianDetails, data?.user?.guardianDetails?.students, localUserIndex])

  const contextValue = useMemo(() => {
    const handleSetLocalUser = (userIndex?: number) => {
      if (!data?.user?.guardianDetails) return
      if (userIndex === undefined) {
        if (isClient) {
          localStorage?.removeItem('local-user')
        }
        setLocalUser(undefined)
        return
      }

      const selectedUser = data?.user.guardianDetails?.students[userIndex]
      setLocalUser(selectedUser)
      if (isClient) {
        localStorage.setItem('local-user', userIndex.toString())
      }
    }
    return { localUser, setLocalUser: handleSetLocalUser }
  }, [localUser, data?.user?.guardianDetails, isClient])

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export function useLocalUserContext() {
  return useContext(Context)
}
